import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const Portfolio = () => {
  return (
    <>
      <SimpleReactLightbox>
        <div className="tokyo_tm_portfolio">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>Travel</span>
                <h3>Cities Visited</h3>
              </div>
            </div>
          </div>
          {/* END TOKYO_TM_TITLE */}
          <div className="portfolio_filter">
            <Tabs>
              <TabList>
                <Tab>Toronto</Tab>
                <Tab>San Francisco</Tab>
                <Tab>Changzhou</Tab>
                <Tab>Shanghai</Tab>
                <Tab>Suzhou</Tab>
              </TabList>
              {/* END TABLIST */}
              <div className="list_wrapper">
                <SRLWrapper>
                  <TabPanel>
                    <ul className="portfolio_list">
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/trt1.jpeg">
                              <img
                                src="assets/img/portfolio/trt1.jpeg"
                                alt="Portfolio"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END VIMEO */}
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/trt2.jpeg">
                              <img
                                src="assets/img/portfolio/trt2.jpeg"
                                alt="portfolio"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END YOUTUBE */}
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/trt3.jpeg">
                              <img
                                src="assets/img/portfolio/trt3.jpeg"
                                alt="portfolio"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END SOUNDCLOUD */}
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/trt4.jpeg">
                              <img
                                src="assets/img/portfolio/trt4.jpeg"
                                alt="portfolio"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                    {/* END PORTFOLIO LIST */}
                  </TabPanel>
                  {/* END ALL PORTFOLIO GALLERY */}

                  <TabPanel>
                    <ul className="portfolio_list">
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/sf1.jpeg">
                              <img
                                src="assets/img/portfolio/sf1.jpeg"
                                alt="Ui/Ux"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END VIMEO */}
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/sf2.jpeg">
                              <img
                                src="assets/img/portfolio/sf2.jpeg"
                                alt="Ui/Ux"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END VIMEO */}
                    </ul>
                    {/* END PORTFOLIO LIST */}
                  </TabPanel>
                  {/* END UI/UX GALLERY */}

                  <TabPanel>
                    <ul className="portfolio_list">
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/cz1.jpeg">
                              <img
                                src="assets/img/portfolio/cz1.jpeg"
                                alt="Website"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END YOUTUBE */}
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/cz2.jpeg">
                              <img
                                src="assets/img/portfolio/cz2.jpeg"
                                alt="Website"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/cz3.jpeg">
                              <img
                                src="assets/img/portfolio/cz3.jpeg"
                                alt="Website"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END YOUTUBE */}
                    </ul>
                    {/* END PORTFOLIO LIST */}
                  </TabPanel>
                  {/* END WEBSITE GALLERY */}

                  <TabPanel>
                    <ul className="portfolio_list">
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/sh1.jpeg">
                              <img
                                src="assets/img/portfolio/sh1.jpeg"
                                alt="Graphic"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END SOUNDCLOUD */}
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/sh2.jpeg">
                              <img
                                src="assets/img/portfolio/sh2.jpeg"
                                alt="Graphic"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/sh3.jpeg">
                              <img
                                src="assets/img/portfolio/sh3.jpeg"
                                alt="Graphic"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/sh4.jpeg">
                              <img
                                src="assets/img/portfolio/sh4.jpeg"
                                alt="Graphic"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END SOUNDCLOUD */}
                    </ul>
                    
                    {/* END PORTFOLIO LIST */}
                  </TabPanel>
                  {/* END GRAPHIC GALLERY */}

                  <TabPanel>
                    <ul className="portfolio_list">
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/sz1.jpeg">
                              <img
                                src="assets/img/portfolio/sz1.jpeg"
                                alt="Creative"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END IMAGE */}
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/sz2.jpeg">
                              <img
                                src="assets/img/portfolio/sz2.jpeg"
                                alt="Creative"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END IMAGE */}
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/sz3.jpeg">
                              <img
                                src="assets/img/portfolio/sz3.jpeg"
                                alt="Creative"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/sz4.jpeg">
                              <img
                                src="assets/img/portfolio/sz4.jpeg"
                                alt="Creative"
                              />
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                    {/* END PORTFOLIO LIST */}
                  </TabPanel>
                  {/* END CREATIVE PORTFOLIO GALLERY */}
                </SRLWrapper>
                {/* END TABPANEL */}
              </div>
              {/* END LIST WRAPPER */}
            </Tabs>
          </div>
        </div>
      </SimpleReactLightbox>
    </>
  );
};

export default Portfolio;
